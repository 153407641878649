<template>
  <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">Тренинговый центр</div>
      </div>
      <div class="card-body">
        <center-form />
      </div>
    </div>
  </div>
</template>

<script>
import CenterForm from "@/feature/partners/forms/CenterForm.vue";
import useCheckRights from "../../mixins/useCheckRights";
export default {
  components: { CenterForm },
  name: "partner-page",
  setup() {
    const { getRight } = useCheckRights();

    return {
      getRight,
    };
  },
};
</script>

<style>
</style>